/* exported debounce */
var slickGallery;

$(function () {
    console.log(1111);
    initTabs();
    initExpand();
    initSlick();
    initNav();
    initJumpLinks();
    initPopup();
});

$(window).on('load', function () {
});

$(window).scroll(function () {
    borderedBlocksScrollAnimation();
});

function borderedBlocksScrollAnimation(){
    $animateBlocks = $('.js-scroll-trigger');
    if($animateBlocks.length > 0){
        var scroll = $(window).scrollTop();
        $animateBlocks.each(function(){
            var pos = $(this).offset().top;
            // console.log('pos: ' + pos);
            var screenHeight = $(window).outerHeight();
            // console.log('height: ' + screenHeight);
            // console.log('comp: ' + (pos - (screenHeight / 2)));
            // Start the animation when the element is 7/8s of the way down the screen
            if(scroll > pos - screenHeight * 3 / 4){
                add_animate_classes($(this));
            }
        })
    }
}

function add_animate_classes($this){
    // console.log('add_animate');
    $this.addClass('animate');
}

/**
 * Inits the nav setup
 */
 function initNav() {
    // open/close site navigation
    $('.js-nav-toggle').off().on('click', function () {
        if(!$(this).hasClass('burger--open')){
            $(this).toggleClass('burger--open');
            $('#nav-main').toggleClass('open');
            if($(window).width() < 840){
                $('.nav__panel').animate({width: '80vw'}, 600, 'swing');
            }
            else{
                $('.nav__panel').animate({width: '420px'}, 600, 'swing');
            }
            $('html, body').toggleClass('site-nav-showing');
            $('.nav__panel-inner').delay(300).animate({opacity: 1}, 600, 'swing');
        }
        return false;
    });
    $('body, .js-nav-close').off().on('click', function(){
        $('.js-nav-toggle').removeClass('burger--open');
        $('.nav__panel-inner').animate({opacity: 0}, 600, 'swing');
        $('.nav__background').animate({opacity: 0}, 600, 'swing');
        $('.nav__panel').delay(300).animate({width: '0px'}, 600, 'swing', function() {
            $('#nav-main').removeClass('open');
        });
        $('html, body').removeClass('site-nav-showing');
    });
    $('.js-nav-panel').off().on('click', function(e){
        e.stopPropagation();
    });
    // $('.js-subnav').off().on('click', function () {
    //     console.log('gere');
    //     $(this).find('.open-link-arrow-outer').toggleClass('open');
    //     $(this).parents('li').find('ul').slideToggle();
    //     return false;
    // });
}

/**
 * Scrolls smoothly to the passed element
 * @param {string} element The element to scroll to
 */
function scrollToElement(element) {
    var offset = $(element).offset().top - 60;
    $('html, body').animate({
        scrollTop: offset
    }, 500);
}

/**
 * Debounce function, taken from http://davidwalsh.name/javascript-debounce-function
 * @param {function} func The code to run when triggered
 * @param {number} wait The function will be called after it stops being called for this number of milliseconds
 * @param {boolean} immediate If passed, trigger the function on the leading edge, instead of the trailing
 */
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function later() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

/**
 * Old adaptive images function, not needed if using imagesSizes
 */
// function init_adp_images() {
// 	$('.adp-image').each(function () {
// 		var image;
// 		if (typeof $(this).attr('data-src-' + get_device()) === 'undefined') {
// 			image = $(this).data("src-desktop");
// 		} else {
// 			image = $(this).data("src-" + get_device());
// 		}

// 		if ($(this).attr('data-bg') === 'true') {
// 			$(this).css("background-image", 'url(' + image + ')');
// 		} else {
// 			$(this).attr("src", image);
// 		}
// 	});
// }

/**
 * Toggle between tabbed content when tabs are clicked
 */
function initTabs() {
    $('.js-tab-link').off().on('click', function () {
        if (!$(this).hasClass('active')) {
            var thisSlug = $(this).attr('data-slug');
            var tabsId = $(this).attr('data-tabs-id');
            var theseTabs = $('.tabs[data-tabs-id="' + tabsId + '"]');
            $(this).addClass('active').siblings().removeClass('active');
            theseTabs.find('.js-tab-content').removeClass('open');
            theseTabs.find('.js-tab-content[data-slug="' + thisSlug + '"]').addClass('open');
        };
        slickGallery.slick('unslick').addClass('slick-loading');
        initSlick();
        return false;
    });
}

/**
 * Show/hide the area of content directly after the expand link
 */
function initExpand() {
    $('.js-expand').off().on('click', function () {
        if ($(this).hasClass('js-close-others')) {
            $(this).parent().find('.js-expand.active').removeClass('active');
            $(this).parent().find('.tab-content.open').removeClass('open');
        }
        $(this).toggleClass('active').next().toggleClass('open');
        return false;
    });
}

/**
 * Initialise slick carousels
 */
function initSlick() {
    setTimeout(function () { // give images a chance to load before starting
        slickGallery = $('.js-slick');
        if (slickGallery.length > 0) {
            slickGallery.slick({
                fade: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000,
                prevArrow: '<a href="#" class="slick-prev"><span class="visuallyhidden">Previous</span></a>',
                nextArrow: '<a href="#" class="slick-next"><span class="visuallyhidden">Next</span></a>',
                dots: false
            });
        }
    }, 200);
}

/**
 * Initialises jump links to smooth scroll down the page
 */
function initJumpLinks() {
    $('.js-jump-link').off().on('click', function () {
        var hash = $(this).attr('href').split('#');
        if ($('#' + hash[1]).length > 0) {
            scrollToElement('#' + hash[1], ($('.site-header').height() - 150));
            return false;
        }
    });
}

function initPopup() {
    $('.popup').on('click', '.close-button', function (event) {
        event.preventDefault();
        /* Act on the event */
        $('.popup-overlay').hide();
    });
    $('.popup').click(function (e) {
        e.stopPropagation();
    });
    $('body').on('click', function (event) {
        /* Act on the event */
        $('.popup-overlay').hide();
    });
    $('.js-open-popup').on('click', function(e){
        $('.popup-overlay').removeClass('hidden').show();
        e.stopPropagation();
    });
}